/*jslint browser: true, nomen: true */
/*global $ */
'use strict';

// utility function check script load time
function getLoadTime() {
	var holder = $('#elapsed-time'), now, timing, load_time
	if (!holder.length) { return; }
	now = new Date().getTime();
	// Get the performance object
	window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
	timing = performance.timing || {};
	if (timing) {
		load_time = now - timing.navigationStart;
		holder.text(' | LT: ' + load_time + 'ms');
	}
}

(function($){
	$.fn.stickyFooter = function () {
		var $wrap = $(this),
			$window = $(window),
			$footer = $('#footer'),
			$footerPad;

		$footerPad = $('<div id="footer-pad"/>');
		$wrap.append($footerPad);

		// adjust margin & padding bottom for element
		function adjustMarginPadding() {
			var adjustment = $footer.outerHeight();

			$wrap.css({
				'margin-bottom': -adjustment
			});

			$footerPad.css({
				'padding-bottom': adjustment,
			});
		}

		return this.each( function() {
			$window.on('resize', $.proxy(adjustMarginPadding, this));
			$.proxy( adjustMarginPadding, this )();
		});
	};
}(jQuery));


/*
	Ensure all external links load as new window/tabs:
 */

function ExternalLinkHandler() {
    var hostname = document.location.hostname;

    this.matchInternalLink = [new RegExp("^http:\/\/(.*?)" + hostname)];

    this.addTargetAttribute = function (context) {

        var links = $(context).find('a').filter('[href^="http://"],[href^="https://"]');

        $.each(links, function () {
            var anchor = $(this);
            var href = anchor.attr('href');

            if (!(href.indexOf(document.domain) > -1 || href.indexOf(':') === -1)) {
                anchor.attr('target', '_blank').addClass('external_link');
            }
        });
    };
}

var App;

App = (function($){
	var bootstrap, share, sliders;

	bootstrap = function() {
		// run external links
		(new ExternalLinkHandler).addTargetAttribute($('body'));

		// Tooltips
		// Disabled by default for performance
		$('[data-toggle="tooltip"]').tooltip();

		// Popovers
		// Disabled by default for performance
		$('[data-toggle="popover"]').popover();

		$('.lightbox').magnificPopup({
			type:'image'
		});

		$('#wrap').stickyFooter();
	};

	sliders = function(){

		$('.slider-for').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			asNavFor: '.slider-nav'
		});

		$('.slider-nav').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			asNavFor: '.slider-for',
			centerMode: true,
			focusOnSelect: true
		});
	};

	share = function(selector) {
		var shareObj = $(selector);

		var shareOptions = {};
		if(shareObj.data('handle')) {
			shareOptions.handle = shareObj.data('handle');
		}
		if (shareObj.data('stacked')) {
			shareOptions.stacked = shareObj.data('stacked');
		}

		shareObj.socialMedia(shareOptions);

	};

	return {
		bootstrap: bootstrap,
		share: share,
		sliders: sliders
	}
}(jQuery));

$(document).ready(function(){

    App.bootstrap();
    App.share('.js-social-share');
    App.sliders();

    $('.slide-banner').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav'
    });

    $('.testimonial').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav'
    });

});

window.onload = function() {
	getLoadTime();
};