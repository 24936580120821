(function ($) {
	$.fn.socialMedia = function (options) {
		function share () {
			var defaults = $.extend({}, {
				handle: '',
				stacked: false
			}, options), handle, stacked, image, title;

			handle = defaults.handle;
			stacked = defaults.stacked;

			image = $('meta[itemprop="image"]').attr('content');
			title = $('meta[property="og:title"]').attr('content');


			$('[data-share="twitter"]').each(function(){
				var twitter = $(this);

				twitter.sharrre({
					share: { twitter: true },
					template: template('twitter', stacked),
					enableHover: false,
					enableTracking: true,
					buttons: { twitter: {via: handle}},
					click: function(api, options){
						options.url = twitter.data('short-uri');
						api.simulateClick();
						api.openPopup('twitter');
					}
				});
			});

			$('[data-share="facebook"]').each(function(){
				var facebook = $(this);
				var image = facebook.data('image');

				facebook.sharrre({
					share: { facebook: true },
					template: template('facebook', stacked),
					enableHover: false,
					enableTracking: true,
					buttons: {facebook: {media: image }},
					click: function(api){
						api.simulateClick();
						api.openPopup('facebook');
					}
				});
			});


			$('[data-share="google"]').each(function(){
				var google = $(this);
				google.sharrre({
					share: { googlePlus: true },
					template: template('google-plus', stacked),
					enableHover: false,
					enableTracking: true,
					urlCurl: '/vendor/sharrre/sharrre.php',
					click: function(api){
						api.simulateClick();
						api.openPopup('googlePlus');
					}
				});
			});

			$('[data-share="linkedin"]').each(function(){
				var linkedin = $(this);
				linkedin.sharrre({
					share: { linkedin: true },
					template: template('linkedin', stacked),
					enableHover: false,
					enableTracking: true,

					click: function(api, options){
						api.simulateClick();
						api.openPopup('linkedin');
					}
				});
			});

			$('[data-share="pinterest"]').each(function(){
				var pinterest = $(this);
				var image = pinterest.data('image');
				var description = pinterest.data('description');

				pinterest.sharrre({
					share: { pinterest: true },
					urlCurl: '/vendor/sharrre/sharrre.php',
					template: template('pinterest', stacked),
					enableHover: false,
					enableTracking: true,
					buttons: {
						pinterest: {media: image, description: description }
					},
					click: function(api, options){
						api.simulateClick();
						api.openPopup('pinterest');
					}
				});
			});
		}


		function template( share, stacked ) {

			if ( !stacked ) {
				return '<i class="fa fa-'+ share +' fa-lg" ></i><span class="share-total">{total}</span>';
			} else {
				return '<span class="fa-stack fa-lg"><i class="fa fa-circle fa-stack-2x"></i><i class="fa fa-'+ share +' fa-stack-1x fa-inverse"></i></span><span class="share-total">{total}</span>';
			}
		}

		return this.each( function() {
			$(window).on('load', $.proxy(share, this));
			$.proxy( share, this )();
		});
	};
})(jQuery);